@font-face {
  font-family: 'icomoon';
  src: url('/font/icomoon.ttf?y5y75n') format('truetype')
;
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  //speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-sort-alpha-asc:before {
  content: "\e911";
}
.icon-history:before {
  content: "\e910";
}
.icon-clear:before {
  content: "\e90d";
}
.icon-chevron-left:before {
  content: "\e900";
}
.icon-chevron-right:before {
  content: "\e901";
}
.icon-chevrons-left:before {
  content: "\e902";
}
.icon-chevrons-right:before {
  content: "\e903";
}
.icon-download:before {
  content: "\e90a";
}
.icon-eye:before {
  content: "\e904";
}
.icon-log-out:before {
  content: "\e907";
}
.icon-phone-call:before {
  content: "\e90f";
}
.icon-refresh-ccw:before {
  content: "\e908";
}
.icon-search:before {
  content: "\e90e";
}
.icon-slash:before {
  content: "\e909";
}
.icon-trash:before {
  content: "\e905";
}
.icon-upload:before {
  content: "\e90b";
}
.icon-pencil:before {
  content: "\e90c";
}
.icon-check:before {
  content: "\e906";
}
