.overviewItem {
  display: inline-block;
  background-color: #EA6413;
  color:rgba(255, 255, 255, 0.9);
  margin: 10px;
  padding: 10px;
}

.overviewItem div {
  display: inline-block;
  text-align: center;
  vertical-align: bottom;
  padding: 15px;
  margin-inline: 10px;
  background-color: white;
  color: black;
}

.burger {
  width: 240px;

  &.change {
    span {
      background: #fff;
    }
  }

  .burger-bar {
    width: 40px;
    cursor: pointer;
  }

  span {
    transition: all .3s;
    height: 4px;
    width: 40px;
    display: block;
    background: #fff;
    margin: .5rem 0;

    &:nth-child(1) {
      width: 23px;
    }

    &:nth-child(3) {
      margin: .5rem 0 .5rem auto;
      width: 23px;
    }

  }

  &:hover {
    span {
      width: 100% !important;
    }
  }
}

.logo {
  display: block;

  img {
    width: 190px;
    vertical-align: middle;
  }
}

.menu {
  width: 240px;
  text-align: right;

  .icon {
    font-size: 1.3rem;
    vertical-align: top;
    display: inline-block;
    margin: 1px 5px 0 0;
  }

  button {
    margin-left: 1.5rem;
    text-decoration: none;
    color: #fff;
  }
}

.flex {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  width: 100%;

  &-item {
    width: 50%;
    padding: 0 2rem;

    &-33 {
      width: 33.33%;
      padding: 0 1rem;
    }

    &-35 {
      width: 35%;
      padding-right: 1rem;
    }

    &-65 {
      width: 65%;
      padding-left: 1rem;
    }    
  }
  &-center {
    align-items: center;
  }
}

.row {
  width: calc(100% + 4rem);
  margin: 0 -2rem;
}

.sidebar {
  background: $lightgrey;
  padding: 2rem;
  width: 280px;
  position: absolute;
  min-height: calc(100vh - 70px);
  left: 0;
  height: 100%;
  bottom: 0;
  top: 0;
  transition: all .3s;

  &.hide {
    left: -100%;
  }

  .adminLinks {
    margin: auto 0 0;
    position: fixed;
    bottom: 1.5rem;
    
    li {
      list-style-type: none
    }

    a {
      font-size: .88rem;
      color: $grey;
      text-decoration: none;

      &:hover, &.active {
        color: $orange;
      }
    }
  }
}

.content {
  width: calc(100% - 280px);
  padding: 2rem 3rem;
  margin-left: 280px;
  transition: all .3s;
  overflow-x: hidden;

  &.full {
    width: 100%;
    margin-left: 0;
  }
}

.navigation {
  display: flex;
  flex-flow: column;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    ul {
      list-style: square;
      margin: -.5rem 0 .4rem;
      padding-left: 1.2rem;

      a {
        margin: .4rem 0;

        &:hover {
          font-weight: normal;
          color: $orange;
        }

        &.active, &:hover {
          color: $orange;

          &:after {
            display: none;
          }
        }
      }
    }
  }

  a, span {
    display: inline-block;
    margin: .8rem 0;
    padding: 0 .8rem 0 0;
    width: auto;
    text-decoration: none;
    transition: all .3s;
    color: inherit;

    &:hover {
      font-weight: bold;
    }

    &.active {
      font-weight: bold;
      position: relative;

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 8px 0;
        border-color: transparent $orange transparent transparent;
        position: absolute;
        right: 0;
        top: .2rem;
      }
    }


  }

  span {

    &:hover {
      font-weight: normal;
    }

    &.active {
      font-weight: bold;
    }
  }
}

.legend {
  background: #fff;
  padding: .5rem;
  box-shadow: $shadow;
  margin-left: 2rem;
  width: auto;
  align-self: flex-end;

  p {
    margin: 0 .5rem;
    color: $grey;
    position: relative;
    padding-left: 1.2rem;
    font-size: .88rem;
    line-height: 1.2rem;

    .status {
      position: absolute;
      left: 0;
      top: .1rem;
      width: 14px;
      height: 14px;
    }
  }

  &.big {
    min-width: 618px;
  }

  .legend-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.show-legend {
  display: none;
}

.status {
  background: $grey;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 3px;
  width: 18px;
  height: 18px;
}

.space, .sub {
  justify-content: space-between;
  flex-flow: row;

  &.last {
    justify-content: flex-end;
  }
}

.lightgrey {
  background: $lines;
}

.yellow {
  background: $yellow;
}

.red {
  background: $red;
}

.blue {
  background: $lightblue;
}

.green {
  background: $green;
}

.pink {
  background: #db61ff;
}

.mixed {
  background: $yellow;
  position: relative;

  background: repeating-linear-gradient(
                  -45deg,
                  $yellow,
                  $yellow 4px,
                  #db61ff 4px,
                  #db61ff 8px
  );
}

.search-bar {
  position: relative;
  width: 100%;
  max-width: 618px;

  .search-btn {
    background: $orange url("/img/lupe.svg") center no-repeat;
    background-size: 25px;
    transition: background-color .3s;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 51px;
    box-shadow: -2px 2px 6px rgba(0, 0, 0, .2);

    &:hover, &:focus {
      background: $blue url("/img/lupe.svg") center no-repeat;
      background-size: 25px;
    }
  }

  input[type=search] {
    padding-right: 51px;

  }

  input {
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    border: none;
    outline: none;
    padding: 1rem;
    font-size: inherit;
    font-family: inherit;
    box-shadow: $shadow;

    &::placeholder {
      opacity: 1;
      color: $grey;
    }
  }
}

.custom-select {
  position: relative;
  min-width: 130px;
  display: block;

  select {
    display: none;
  }

  .select-selected {
    background: none;
    border: 1px solid $lines;
    width: 100%;
    height: 35px;
    overflow: hidden;
    word-break: break-all;
    display: inline-block;
    padding: .233rem 1.5rem .233rem .5rem;
    cursor: pointer;
    vertical-align: middle;
    line-height: 1.6rem;
    position: relative;

    &:after {
      content: '';
      background: url("/img/select.svg") center no-repeat;
      background-size: 8px;
      width: 15px;
      height: 100%;
      top: 0;
      bottom: 0;
      position: absolute;
      font-size: 1.3rem;
      right: .5rem;
    }
  }

  .select-hide {
    display: none;
  }

  .select-items {
    position: absolute;
    background: $lightgrey;
    left: 0;

    box-shadow: $shadow;
    top: 100%;
    right: 0;
    text-align: left;
    z-index: 2;

    span {
      display: block;
      color: #181F33;
      transition: all .3s;
      padding: .7rem;
      cursor: pointer;

      &.same-as-selected, &:hover {
        color: #EA6413;
      }

      &.same-as-selected {
        color: #bfbfbf;
      }
    }
  }
}

.data-table {
  overflow-x: auto;
  overflow-y: hidden;
  margin: 2rem auto 1rem;
}

.dataTables_info {
  color: $grey;
  font-size: .88rem;
  float: left;
  margin-bottom: .5rem;
}

.dataTables_paginate {
  margin-bottom: .5rem;
  float: right;
  color: $grey;
  font-size: 1rem;
  outline: none;


  .paginate_button {
    outline: none;
    cursor: pointer;
    padding: .05rem .25rem;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    margin: 0 .3rem;
    align-items: center;
    min-width: 26px;
    height: 26px;
    line-height: 1rem;

    &:hover, &:focus {
      color: $orange;
    }

    &.current {
      color: #fff;
      background: $orange;
    }

  }
}

table {
  text-align: left;
  border-collapse: collapse;
  font-size: .95rem;
  line-height: 1.35rem;
  width: 100%;

  &#table {
    margin-bottom: 1rem;
  }

  td:first-child {
    width: 28px;
    padding: .3rem;
  }

  tr {
    border-bottom: 1px solid $lines;
  }

  th, td {
    overflow-x: hidden;
    text-overflow: ellipsis;
    //max-width: 130px;
    padding: .5rem;
  }

  th {
    color: $grey;
    text-align: left;
    outline: none;
    position: relative;
    padding-top: 0;
    height: 35px;
    font-weight: 500;

    .icon {
      position: absolute;
      right: .5rem;
      top: .6rem;
      cursor: pointer;
      font-weight: bold;
    }

    &.search {
      padding-right: 1.5rem;
      outline: none;

      &:after {
        cursor: pointer;
        font-family: 'icomoon' !important;
        position: absolute;
        right: .25rem;
        content: "\e911";
        display: block;
        top: .4rem;
      }
    }

    &:nth-child(2) {
      min-width: 150px;
    }
  }

  &.dashboard {
    min-width: 1080px;

    tbody tr {
      cursor: pointer;
      transition: all .3s;

      &:hover {
        background: $lightgrey;
      }
    }

    td:nth-child(8) {
      padding-right: 0;
      overflow: visible;
    }

    td:nth-child(9) {
      min-width: 145px;

    }
    td:nth-child(10) {
      max-width: 310px;
    }
    .custom-select {
      padding-right: .5rem;

      .select-items {
        margin-right: .5rem;
      }
    }
  }

  tfoot th:nth-child(1) input, tfoot th:nth-child(2) input {
    display: none;
  }
}

.tooltip {
  position: absolute;
  background: $orange;
  color: #fff;
  font-size: .73rem;
  bottom: calc(100% + .7rem);
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  width: max-content;
  line-height: 1rem;
  padding: .2rem .4rem;
  display: none;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 5px 0 5px;
    border-color: $orange transparent transparent transparent;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 100%;
  }
}

.commercial-table {
  margin-bottom: 1rem;
  overflow: auto;
  padding-top: 2rem;
  margin-top: -2rem;

  table {
    min-width: 1000px;

    .status {
      background: none;
      cursor: pointer;
      position: relative;
      transition: all .3s;
      border: 1px solid #e1e1e1;

      &:after {
        right: -1.5rem;
        top: -.1rem;
        cursor: default;
        color: #ccc;
        position: absolute;
        font-family: 'icomoon' !important;
        content: "\e904";
      }

      &:hover, &:focus {
        box-shadow: $shadow;
      }
    }

    .icon {
      vertical-align: middle;
      margin-left: .5rem;
      margin-bottom: .1rem;
      color: #ccc;
      display: inline-block;
    }

    th {
      position: relative;
      overflow: visible;

      &:hover .tooltip {
        display: block;
      }

      .tooltip {
        left: -.5rem;

        &:after {
          left: .7rem;
          right: auto;
        }
      }

      .icon {
        margin: 0;
        font-size: 1.05rem;
        vertical-align: baseline;
        position: relative;
        color: $grey;
      }
    }

    td {
      padding: .5rem;

      &:first-child, &:nth-child(9) {
        width: 55px;
        min-width: 1px;
      }

      &:nth-child(9) {
        text-align: right;
      }
    }
  }

  .detail-link i {
    color: $orange;
  }

  tr {
    &.green {
      background: rgba(80, 197, 149, 0.28);

      .status {
        background: $green;
        border: none;

        &:after {
          color: $orange;
        }
      }
    }

    &.yellow {
      background: rgba(249, 192, 50, 0.2);

      .status {
        background: $yellow;
        border: none;

        &:after {
          color: $orange;
        }
      }
    }

    &.red {
      background: rgba(239, 76, 67, 0.2);

      .status {
        background: $red;
        border: none;

        &:after {
          color: $orange;
        }
      }
    }
  }
}

.add-input {
  position: relative;
  display: block;

  .text {
    max-height: 35px;
    padding-right: 2rem;
  }

  .add {
    width: auto;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: #c5c5c5;
    padding: 0 .4rem;
    border: none;
    cursor: pointer;
    transition: all .3s;
    font-size: 1.5rem;
    color: #fff;

    &:hover {
      background: $grey;
    }
  }
}

.pagination {
  font-size: .9rem;

  span {
    vertical-align: middle;
    width: 26px;
    height: 26px;
    line-height: 28px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    color: $grey;
    margin: 0 .3rem;

    &:hover {
      color: $orange;
    }

    &.active {
      background: $orange;
      color: #fff;
      box-shadow: $shadow;
    }
  }

  .icon {
    font-size: 1.2rem;
    line-height: 1rem;
    vertical-align: middle;
  }

  .disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.pages {
  margin: 0;
  color: $grey;
  font-size: .85rem;
}

.symbol, .month {
  position: relative;
  align-self: flex-start;
  margin-top: .5rem;
  padding-left: 2.5rem;

  &:before {
    position: absolute;
    left: 0;
    content: '';
    background: url("/img/checkliste.svg") center no-repeat;
    background-size: 27px;
    display: inline-block;
    bottom: .2rem;
    top: 0;
    margin: auto;
    width: 30px;
    height: 30px;
  }
}

.year:before {
  background: url("/img/urkunde.svg") center no-repeat;
  background-size: 28px;
}

.commercial {
  margin-bottom: 2rem;

  .symbol:before {
    background: url("/img/vermarktung.svg") center no-repeat;
    background-size: 28px;
  }

  .legend.big {
    min-width: 514px;
    margin-bottom: .25rem;
  }
}

.bigger {
  font-size: 1.1rem;
  margin-bottom: 0;
}

.sell {
  h2 {
    color: $yellow;
    margin-top: .5rem;
    padding-right: 1.5rem;
  }

  .symbol:before {
    background: url("/img/verkaufen.svg") center no-repeat;
    background-size: 32px;
    vertical-align: sub;
    width: 33px;
    height: 33px;
  }

  .search-bar .search-btn {
    background: url("/img/lupe.svg") center no-repeat $yellow;
    background-size: 25px;

    &:hover {
      background: url("/img/lupe.svg") center no-repeat $blue;
      background-size: 25px;
    }
  }

  .pagination span.active {
    background: $yellow;
  }

  &.details {

    .flex-item {
      min-height: 100%;
    }

    //.flex-item:nth-child(1) {
    //  .shadow-box:first-child {
    //    min-height: calc(60% - 1rem);
    //  }
    //
    //  .shadow-box:last-child {
    //    min-height: calc(40% - 2rem);
    //  }
    //}
    //
    .flex-item:nth-child(2) {
      .shadow-box:first-child {
        min-height: calc(43.25% - 1rem);
      }

      .shadow-box:last-child {
        min-height: calc(30% - 1rem);
      }
    }
  }

  /*
  .space {
    flex-flow: row wrap;
  }
  */

  .legend {
    width: 100%;
    box-shadow: none;

    &-content {
      justify-content: flex-end;
    }
  }
}

.evaluate {
  h2 {
    color: $green;
    margin-top: .5rem;
    padding-right: 1.5rem;
  }

  .symbol:before {
    background: url("/img/bewerten.svg") center no-repeat;
    background-size: 32px;
    vertical-align: sub;
    width: 33px;
    height: 33px;
  }

  .search-bar .search-btn {
    background: url("/img/lupe.svg") center no-repeat $green;
    background-size: 25px;

    &:hover {
      background: url("/img/lupe.svg") center no-repeat $blue;
      background-size: 25px;
    }
  }

  .pagination span.active {
    background: $green;
  }

  .detail-link i {
    color: $green;
  }

  .email-text {
    display: none;

    &.open {
      display: flex;
    }
  }
}

.finance {
  h2 {
    color: $lightblue;
    margin-top: .5rem;
    padding-right: 1.5rem;
  }

  .symbol:before {
    background: url("/img/finanzieren.svg") center no-repeat;
    background-size: 32px;
    vertical-align: sub;
    width: 33px;
    height: 33px;
  }

  .search-bar .search-btn {
    background: url("/img/lupe.svg") center no-repeat $lightblue;
    background-size: 25px;

    &:hover {
      background: url("/img/lupe.svg") center no-repeat $blue;
      background-size: 25px;
    }
  }

  .pagination span.active {
    background: $lightblue;
  }

  .lead-table {
    table td {
      &:nth-child(6) {
        text-align: right;
      }
    }
  }

  &.details .flex-item:first-child .shadow-box {
    &:first-child {
      min-height: 1px;
    }

    /*
    &:last-child {
      min-height: calc(60% - .7rem);
    }
    */

    .domain, .utm {
      padding-top: 1.5rem;
    }
  }
}

.top {
  text-align: right;
  max-width: 530px;
  width: 100%;

  .legend {
    margin: 0;
    background: none;
    box-shadow: none;

    &-content {
      justify-content: flex-end;
    }
  }
}

.statistic {
  .custom-select {
    margin-top: 2rem;
    max-width: 170px;
    font-size: .95rem;

    .select-selected {
      max-height: none;
      padding: .4rem .5rem;
      border: none;
      background: $blue;
      color: #fff;

      &:after {
        background: url("/img/select-white.svg") center no-repeat;
        background-size: 8px;
      }
    }

    .select-items {
      padding: .3rem 0;
      max-height: 260px;
      overflow: auto;

      span {
        padding: .35rem .7rem;
      }
    }
  }
  /*
  .flex.sub {
    margin: 1.5rem 0 .426rem;
  }
  */
  &-table {
    overflow: auto;
    margin-bottom: 1rem;

    table {
      min-width: 385px;

      th, td {
        text-align: right;
      }

      td {
        padding: .7rem .5rem;
      }

      td:first-child, th:first-child {
        text-align: left;
        width: auto;
      }
    }
  }

  .disabled {
    display: none;
  }
}

.shadow-box {
  background: #fff;
  box-shadow: $shadow;
  padding: 1rem;
  min-height: calc(100% - 105px);
}

.domain, .utm {
  padding-top: .5rem;
  display: block;
  margin: 2rem 0 0 0;

  &.smaller {
    width: calc(100% - 140px);

  }
}

.domain {
  position: relative;
  padding-left: 2rem;
  font-weight: bold;
  text-decoration: none;
  color: inherit;
  width: calc(100% - 140px);
  transition: all .3s;

  &:hover {
    color: $orange;
  }

  &:before {
    position: absolute;
    left: 0;
    content: '';
    background: url("/img/domain.svg") center no-repeat;
    background-size: 23px;
    display: inline-block;
    bottom: 0;
    width: 25px;
    height: 25px;
  }
}

.utm {
  font-size: .88rem;

  span {
    color: $grey;
  }
}

.detail-link {
  text-decoration: none;
  color: $grey;

  i {
    border: 2px solid;
    color: $yellow;
    text-align: center;
    font-size: .88rem;
    font-weight: bold;
    width: 18px;
    height: 18px;
    line-height: 16px;
    font-style: normal;
    vertical-align: text-bottom;
    display: inline-block;
    margin-right: .3rem;
  }
}

.lead-table {
  overflow: auto;
  margin: 1rem auto;

  table {
    min-width: 1070px;

    tbody tr {
      transition: all .3s;

      &:hover {
        cursor: pointer;
        background: $lightgrey;
      }
    }
  }
}

.bill-table {
  overflow: auto;
  margin: 2rem auto 1rem;

  table {
    min-width: 1070px;

    tbody tr {
      cursor: pointer;
      transition: all .3s;

      &:hover {
        background: $lightgrey;
      }
    }
  }
}

.sold {
  &-table {
    overflow: auto;
    margin: 1rem auto;

    table td:first-child {
      width: auto;
    }

    tbody tr {
      transition: all .3s;

      &:hover {
        cursor: pointer;
        background: $lightgrey;
      }
    }

  }

  &-head {
    .symbol {
      color: $green;

      &:before {
        background: url("/img/verkauft.svg") center no-repeat;
        background-size: 30px;
      }

      &.uninterested {
        color: $red;

        &:before {
          background: url("/img/desinteressiert.svg") center no-repeat;
          background-size: 30px;
        }
      }
    }
  }
}

.export {
  background: #fff;
  border: 2px solid $blue;
  font-family: inherit;
  font-size: .95rem;
  color: $blue;
  outline: none;
  padding: .5rem 1rem;
  display: inline-block;
  margin: 1rem 0 0;
  cursor: pointer;
  transition: all .4s;

  &:before {
    font-family: 'icomoon' !important;
    margin-right: .3rem;
    font-size: 1.1rem;
    vertical-align: text-top;
    content: "\e90a";
  }

  &:hover, &focus {
    background: $blue;
    color: #fff;
  }
}

.underlined {
  margin: .5rem 0 1rem;
  padding-bottom: .4rem;
  align-self: flex-start;
  width: 100%;
  border-bottom: 2px solid $blue;
}

.details {
  .symbol {
    width: 100%;
    padding-right: 0;
  }

  .flex-item:first-child {
    padding-right: .7rem;
  }

  .flex-item:last-child {
    padding-left: .7rem;
  }

  .shadow-box {
    flex-flow: row wrap;
    margin-bottom: 1rem;
    position: relative;
    min-height: 1px;

    .btn-blue {
      width: 140px;
      margin-bottom: 0;

      &.hide {
        display: none;
      }
    }
  }

  .icon-pencil {
    position: absolute;
    right: 1rem;
    top: 1.3rem;
    font-size: 1.3rem;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      color: $orange;
    }
  }

  .loader {
    height: auto;
    width: 140px;
    align-self: flex-end;
    text-align: center;
    margin: .48rem 0;

    img {
      width: 40px;
    }
  }

  &-info {
    display: flex;
    align-self: flex-start;
    flex-flow: row wrap;
    position: relative;

    p, label, .right {
      width: 55%;
      align-self: center;
      margin: .4rem 0;

      &.grey {
        width: 45%;
        color: $grey;
        padding-right: 1rem;
      }

      &.bold {
        font-weight: bold;
        padding-bottom: .2rem;
        margin: 1rem 0 .5rem;
        border-bottom: 1px solid;
        width: 100%;
      }

      .select-selected {
        margin: 0;
      }

      p {
        margin: 0;
      }
    }

    .edit {
      input, .select-selected {
        padding: 0;
        pointer-events: none;
        border: none;
        height: auto;

        &:after {
          display: none;
        }
      }

      .radio {
        margin: .2rem 1rem .2rem 0;
        pointer-events: none;
        font-size: .9rem;

        &:last-child {
          margin-right: 0;
        }
      }

      &.upload {
        display: none;
        outline: none;

        label {
          position: relative;
          display: inline-block;
          width: auto;

          input {
            cursor: pointer;
          }
        }
      }

      &.active {
        input, .select-selected {
          pointer-events: auto;
          padding: .5rem;
          border: 1px solid $lines;
        }

        .select-selected {
          padding: .25rem .5rem;

          &:after {
            display: block;
          }
        }

        .radio {
          pointer-events: auto;
        }

        &.upload {
          display: block;
        }
      }
    }

    .btn-download {
      padding: .5rem 1rem;
      background: none;
      border: 2px solid $blue;
      color: $blue;
      margin: .5rem 0;
      min-width: 229px;

      .icon {
        margin-right: .5rem;
        vertical-align: text-top;
        font-size: 1.1rem;
      }

      &:hover {
        color: #fff;
      }
    }

  }

  .select-selected {
    max-height: none;
    padding: .4rem 1.5rem .4rem .5rem;
  }

  .flex-item:first-child {
    .shadow-box {
      display: flex;
      flex-flow: column;
      // min-height: calc(50% - 1rem);

      .flex {
        margin: auto 0 0;
      }
    }
  }

  .btn {
    align-self: flex-end;
  }
}

.email-text {
  display: flex;
  flex-flow: row wrap;
  width: 100%;

  .textarea {
    min-height: 180px;
    border-bottom: 0;

    &.email-footer {
      border-top: 0;
      margin-top: -7px;
      border-bottom: 1px solid $lines;
    }
  }

  .alternative {
    margin-bottom: 1.5rem;
  }

  p, label {
    width: 55%;
    align-self: center;
    margin: .4rem 0;

    &.grey {
      width: 45%;
      color: $grey;
      margin: .5rem 0 1.5rem;
      padding-right: 1rem;
    }

    &.bold {
      font-weight: bold;
      padding-bottom: 0;
      margin: 1rem 0 0;
      border: none;
      width: 100%;
    }

    .select-selected {
      margin: 0;
    }
  }
}

.textarea {
  border: 1px solid $lines;
  resize: none;
  outline: none;
  padding: .5rem;
  width: 100%;
  text-align: left;
  font-family: inherit;
  font-size: .95rem;
  line-height: 1.3rem;
  min-height: 100px;

  &.email-footer {
    resize: none;
    font-size: .85rem;
    min-height: 1px;
    height: 40px;
    color: $grey;
  }
}

.select-wrapper {
  position: relative;

  select {
    display: none;
  }

  .caret {
    position: absolute;
    font-size: 1rem;
    top: 0;
    bottom: 0;
    display: flex;
    right: .5rem;
    align-items: center;

    &:after {
      content: '';
      background: url("/img/select.svg") center no-repeat;
      background-size: 8px;
      width: 15px;
      height: 100%;
    }
  }

  .select-btn {
    background: 0 0;
    border: 1px solid #E5E5E5;
    width: 100%;
    overflow: hidden;
    text-align: left;
    word-break: break-all;
    display: inline-block;
    padding: .4rem 1.5rem .4rem .5rem;
    cursor: pointer;
    vertical-align: middle;
    outline: none;
    font-size: 1rem;
    font-family: inherit;
    line-height: 1.6rem;
  }

  .select-list {
    display: none;
    position: absolute;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;

    background: $blue;
    color: #fff;
    margin: 0;
    padding: .7rem .7rem 0;
    list-style: none;


    li.search {
      height: 45px;
      display: flex;
      align-items: center;
      background: #fff;

      .icon {
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        color: $grey;
        padding: 0 .4rem;
      }

      .clear {
        display: none;
        align-items: center;
        height: 100%;
        color: $grey;
        padding: 0 .4rem;
        font-size: 1.25rem;
        cursor: pointer;
      }

      input {
        height: 100%;
        border: none;
        border-radius: 0;
        padding: 0;
        flex-grow: 1;
        background: transparent;
      }
    }

    li.no-result {
      display: none;
      height: 50px;
      line-height: 50px;
      text-align: center;
    }

    li.option {
      height: 50px;
      user-select: none;
      cursor: pointer;
      position: relative;
      display: flex;
      transition: all .3s;
      align-items: center;
      color: #fff;

      &:hover {
        color: $orange;
      }

      &[selected] {
        background: $orange;
        margin: 0 -.7rem;
        padding: 0 .7rem;

        &:hover {
          color: #fff;
          opacity: .9;
        }
      }

      &[disabled] {
        opacity: .25;
      }

      &[placeholder] {
        display: none !important;
      }
    }
  }
}

.btn {
  background: $orange;
  margin: .5rem 0 .5rem auto;
  text-decoration: none;
  font-size: inherit;
  outline: none;
  text-align: center;
  font-family: inherit;
  line-height: 1.4rem;
  display: block;
  border: none;
  color: #fff;
  width: auto;
  cursor: pointer;
  transition: all .4s;
  padding: .7rem 1.5rem;

  &:hover, &:focus, &-blue {
    background: $blue;
  }

  &-blue:hover, &-blue:focus {
    background: $orange;
  }

  &-border {
    background: #fff;
    border: 2px solid;
    padding: .4rem;
    line-height: 20px;
    margin: .5rem 0 1rem 0;
    color: $blue;
    align-self: center;
    min-width: 95px;
    font-size: .95rem;

    .icon {
      vertical-align: text-top;
      font-size: 1.2rem;
      margin-right: .2rem;
    }

    &:hover {
      border: 2px solid $blue;
      color: #fff;
    }
  }

  &-no-margin {
    margin: 0;
  }

  &-new {
    margin: 2rem 0 0;
    padding: .7rem 1rem;

    span {
      margin-right: .2rem;
      font-size: 1.5rem;
      vertical-align: top;
    }
  }

  &-icon {
    display: inline;
    margin-left: .4rem;
    color: black;
    background-color: inherit;
  }
}

.interactions {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
  padding: 0 1rem;

  .delete {
    margin-left: 0;
    align-self: center;
  }
}

.delete {
  font-size: .95rem;
  text-decoration: underline;
  color: $grey;
  background: none;
  outline: none;
  border: none;
  font-family: inherit;
  padding-left: 1.5rem;
  margin-left: 1rem;
  position: relative;
  cursor: pointer;
  transition: all .3s;

  &:before {
    font-family: 'icomoon' !important;
    content: "\e905";
    position: absolute;
    text-decoration: none;
    font-size: 1.1rem;
    width: 20px;
    left: 0;
  }

  &:hover, &:focus {
    color: $red;
  }
}

.line {
  height: 3px;
  border-radius: 30px;
  background: $orange;
  width: 65px;
  text-align: left;
  border: none;
  margin: .5rem 0 1.2rem;
}

.radio {
  cursor: pointer;
  display: inline-block;
  position: relative;
  margin: .5rem 0;

  &:first-child {
    margin-right: 1.5rem;
  }

  &-label {
    width: 100% !important;
  }

  input[type="radio"] {
    position: absolute;
    opacity: 0;

    + .radio-label:before {
      content: '';
      background: none;
      border-radius: 0;
      border: 1px solid $lines;
      display: inline-block;
      width: 22px;
      height: 22px;
      position: relative;
      top: -.1rem;
      margin-right: .7rem;
      vertical-align: middle;
      cursor: pointer;
      text-align: center;
      transition: all .3s;
    }

    &:checked + .radio-label:before {
      background-color: #EA6413;
      box-shadow: inset 0 0 0 5px #fff;
    }
  }
}

.average {
  color: $orange;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: .5rem;
}

.full {
  width: 100%;
}

.broker {
  &-table {
    padding-top: 2rem;
    margin: -1rem 0 1rem;
    overflow: auto;

    .detail-link {
      position: relative;
      cursor: help;

      &:hover .tooltip {
        display: block;
      }

      .tooltip {
        right: -.5rem;

        &:after {
          left: auto;
          right: 1.05rem;
        }
      }

      i {
        color: $grey;
      }
    }

    table td {
      padding: .4rem .5rem;

      .icon-eye {
        background: $lines;
        cursor: pointer;
        width: 25px;
        height: 25px;
        top: 0;
        bottom: 0;
        margin: auto;
        color: #fff;
        position: absolute;
        right: 0;
        padding: .3rem;
        transition: all .4s;

        &:hover, &:focus, &.active {
          box-shadow: $shadow;
          background: $orange;
        }
      }

      &:nth-child(5) {
        position: relative;
        padding-right: 2rem;
      }

      &:nth-child(n+6) {
        text-align: right;
        width: 160px;
      }

      &:last-child {
        width: 70px;
      }
    }

    table th:nth-child(n+6) {
      text-align: right;
    }

    table th:last-child {
      overflow: visible;
    }

    .edit-broker {
      display: none;
      border: none;
      padding-bottom: 1rem;
      cursor: inherit;

      &:hover {
        background: inherit;
      }

      td {
        margin-bottom: 1rem;
        box-shadow: $shadow;
        position: relative;

        .icon-clear {
          position: absolute;
          z-index: 1;
          right: 1rem;
          top: 1rem;
          font-size: 1.4rem;
        }
      }

      label {
        display: block;
        margin-bottom: .6rem;
        font-size: .85rem;

        input, .select-selected {
          border: none;
          height: 35px;
          background: #eee;
        }
      }

      p {
        font-size: .85rem;
        margin: 0;
      }

      .block {
        display: none;

        &.show {
          display: block;
        }
      }

      .radio {
        margin-right: 1rem;
      }

      .radio input[type=radio] + .radio-label:before {
        border: none;
        background: #eee;
      }

      .radio input[type=radio]:checked + .radio-label:before {
        box-shadow: inset 0 0 0 5px #eee;
        background: $orange;
      }

      .row {
        margin: 0 -.5rem;
        width: calc(100% + 1rem);
      }

      .headline {
        padding: 1rem;
        width: 100%;
      }

      h2 {
        margin: 0 0 .5rem;
      }

      .line {
        margin-bottom: 0;
      }

      h3 {
        align-self: flex-end;
        margin: -2.3rem 0 .5rem;
        width: 100%;
      }

      &.show {
        display: table-row;
      }

      .btn {
        min-height: 45px;
        margin: .5rem 0 2rem auto;

        &.hide {
          display: none;
        }
      }

      .custom-select .select-items {
        max-height: 115px;
        overflow: auto;

        span {
          padding: .5rem .7rem;
        }
      }

      textarea {
        display: flex;
        width: 100%;
        height: 12em; 
        resize: none; 
        padding: 0.5em;

        &.small {
          height: 7em; 
        }
      }
    }

    .inactive {
      opacity: .45;

      .icon-eye {
        background: #ccc;

      }
    }

    .blocked {
      color: $red;

      .contract img {
        opacity: .45;
      }

      .icon-eye {
        background: rgba(239, 76, 67, 0.5);

        &:hover, &:focus, &.active {
          background: $red;
        }
      }

      td:first-child {
        &:before {
          font-family: 'icomoon' !important;
          vertical-align: text-bottom;
          margin-right: .2rem;
          content: "\e909";
        }
      }

      + .edit-broker {
        .block {
          display: block;
        }
      }
    }

    .blocked, .inactive {
      display: none;

      &.show {
        display: table-row;
      }
    }

    .contract {
      position: relative;
      filter: grayscale(1);
      cursor: pointer;

      img {
        width: 23px;
        vertical-align: middle;
      }

      &.available {
        filter: none;
      }
    }
  }

  .search-bar {
    align-self: flex-start;
  }

  .legend {
    box-shadow: none;

    .legend-content {
      justify-content: flex-end;
    }
  }
}

.invoice {
  cursor: pointer;
  text-decoration: underline;
  margin: 0;

  span {
    margin: 0 .4rem 0 0;
    text-decoration: none;
  }

  &:hover {
    color: #666;
  }
}

.list-location {
  list-style: square;
  padding: 0 0 0 1.2rem;
  margin: .5rem 0;

  li {
    position: relative;
    padding: .2rem 2rem .2rem 0;

    &.remove {
      display: none;
    }

    .icon {
      font-size: 1.2rem;
      position: absolute;
      cursor: pointer;
      right: .4rem;
      transition: all .3s;

      &:hover {
        color: $orange;
      }
    }
  }
}

label.location {
  position: relative;

  button {
    padding-right: 2px;

    &.btn-add {
      padding: .5rem;
      width: 35px;
      cursor: pointer;
      line-height: 20px;
      font-size: 1.5rem;
      position: absolute;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, .3);
      background: $blue;
      margin: 0;
      color: #fff;
      bottom: 0;
      right: 0;
      transition: all .3s;
      box-shadow: 0;
      border: 0;
      &:hover {
        background: $orange;
      }
    }
  }
}

.loader {
  width: 100%;
  max-width: 250px;
  height: 45px;
  margin: .5rem 0 2rem auto;
  display: none;
  justify-content: center;
  align-items: center;

  &.show {
    display: flex;
  }

  img {
    width: 40px;
    height: auto;
    margin: auto;
  }
}

.document {
  &-add {
    background: none;
    border: none;
    font-family: inherit;
    width: 100%;
    font-size: 1rem;
    color: $orange;
    text-decoration: underline;
    outline: none;
    position: relative;
    padding: 0 1.2rem 0 0;
    cursor: pointer;
    transition: all .3s;
    text-align: left;

    .icon {
      font-style: normal;
      font-size: 1.6rem;
      right: 2px;
      top: -7px;
      position: absolute;
    }

    &:hover {
      color: $blue;
    }
  }

  &-list {
    margin: .5rem 0;
    padding: 0;
    list-style: none;

    li {
      padding: .3rem 1.2rem 0 0;
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      position: relative;
      color: $orange;

      a {
        font-weight: normal;
        color: $orange;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .icon {
        font-size: 1.1rem;
        right: 0;
        top: 7px;
        font-weight: bold;
        position: absolute;
        cursor: pointer;
        transition: all .3s;

        &:hover {
          color: $blue;
        }
      }
    }
  }
}

.add-location {
  display: none;

  &.show {
    display: block;
  }
}

.personal {
  width: 66.66%;
  flex-flow: row wrap;
  display: none;

  h3 {
    width: 100%;
  }

  .flex-item-33 {
    width: 50%;
  }

  &.show {
    display: flex;
  }
}

.bottom {
  align-self: flex-end;
  width: 100%;

  &.smaller {
    width: 33.33%;
  }
}

.show-other, .sold {
  max-width: 700px;
  font-size: .88rem;
  line-height: 1.2rem;
  color: $grey;
  position: relative;
  padding: .3rem .5rem .3rem 2.1rem;
  background: #fff;
  margin-bottom: .3rem;
  box-shadow: $shadow;

  label {
    vertical-align: sub;
  }

  input {
    width: 100%;
    display: block;
    font-size: 15px;
    padding: .5em;
    margin: 0;
    cursor: pointer;
    top: .5rem;
    outline: 0;
    border: 2px solid $grey;

    &[type=checkbox] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: inline-block;
      position: absolute;
      transition: all .4s;
      left: .5rem;
      width: 1em;
      height: 1em;

      &:checked {
        background: $grey;

        &:before {
          font-family: 'icomoon' !important;
          content: "\e906";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          border: 0;
          color: #fff;
        }
      }
    }

  }
}

.sold {
  width: auto;
  align-self: flex-end;
  background: none;
  box-shadow: none;
  position: relative;
  padding-left: 1.7rem;

  label {
    cursor: pointer;
  }

  input[type=checkbox] {
    border: 2px solid $green;
    left: 0;

    &:checked {
      background: $green;
    }
  }

  .link {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.login {
  background: $lightgrey;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;

  h2 {
    margin-top: 0;
  }

  .flex {
    max-width: 900px;
  }

  .left {
    background: $blue;
    color: #fff;
    margin: 2rem 0;
    width: 45%;
    padding: 2rem 4rem 2rem 2rem;
    display: flex;
    flex-flow: column;

    .logo {
      width: 250px;
      display: block;
      margin-bottom: 2rem;
    }

    h1 {
      max-width: 300px;
      font-size: 1.1rem;
      line-height: 1.7rem;
      font-weight: normal;
    }
  }

  .right {
    background: #fff;
    padding: 3rem;
    width: 55%;
    box-shadow: $shadow;
  }

  label {
    font-size: .88rem;
    line-height: 1.2rem;
    display: block;
    margin-bottom: .9rem;

    input {
      margin-top: .2rem;
      background: $lightgrey;
      border: none;
      font-size: 1rem;
      padding: .7rem;
    }
  }

  .btn {
    display: inline-block;
  }

  .forgot {
    width: 100%;
    color: $grey;
    margin-top: -.3rem;
    font-size: .88rem;
    text-decoration: none;
    display: block;
    transition: all .3s;

    &:hover {
      color: $blue;
    }
  }
}

.sort {
  position: relative;
  padding-left: .8rem;
  cursor: pointer;

  &:before {
    content: '';
    background: url("/img/sort.svg") top center no-repeat;
    background-size: 6px;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 1.5px;
    width: 10px;
    height: 20px;
  }
}

.assign {
  display: flex;

  .select-selected {
    height: auto;
  }

  .icon-history, .icon-clear {
    font-size: 1.5rem;
    position: absolute;
    right: 1rem;
    top: 1.3rem;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      color: $orange;
    }
  }

  h3 {
    margin-bottom: .5rem;
  }

  .history {
    width: auto;
    margin: auto;
    position: absolute;
    background: rgba(255, 255, 255, 0.9);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    display: none;
    flex-flow: column;
    justify-content: center;
    padding: 2rem 0;
    align-items: center;

    p {
      color: $orange;
      margin-top: 0;
    }

    div {
      padding: 0 2rem;
      //overflow: auto;
    }

    &.show {
      display: flex;
    }

    ol {
      padding: 0 0 0 2rem;

      li {
        padding: .15rem 0;
      }
    }
  }
}

.upload {
  width: 100%;

  .input-file-trigger {
    display: block;
    padding: .7rem;
    min-height: 40px;
    background: #fff;
    color: $blue;
    border: 2px solid $blue;
    text-align: center;
    font-size: 1em;
    transition: all .3s;
    cursor: pointer;

    .icon {
      margin-right: .5rem;
      font-size: 1.1rem;
    }
  }

  .input-file {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    opacity: 0;
    min-height: 40px;
    cursor: pointer;
  }

  .input-file:hover + .input-file-trigger,
  .input-file:focus + .input-file-trigger,
  .input-file-trigger:hover,
  .input-file-trigger:focus {
    color: #fff;
    background: $blue;
  }

  .file-return {
    font-size: .88rem;
    font-weight: bold;
  }

  .file-return:not(:empty):before {
    content: "Datei: ";
    font-style: normal;
    font-weight: normal;
  }
}

.file-return {
  margin: 0;
}

.file-return:not(:empty) {
  margin: 1em 0;
}

.sign {
  align-items: center;
  margin: .5rem 0 1.5rem;
  display: none;

  &.show {
    display: flex;
  }

  .btn {
    padding: .5rem 1rem;
    margin: 0 .5rem 0 0 !important;

    &:before {
      margin-right: .3rem;
      font-size: 1.2rem;
      vertical-align: text-bottom;
      font-family: 'icomoon' !important;
      content: "\e90a";
    }
  }
}

.no-sign {
  margin: .5rem 0 1.5rem;
  display: flex;

  div {
    position: relative;
  }

  &.hide {
    display: none;
  }

  .btn {
    padding: .5rem 1rem;
    margin: 0 !important;
    width: 100%;
    text-align: center;

    &:before {
      margin-right: .4rem;
      font-size: 1.2rem;
      vertical-align: text-bottom;
      font-family: 'icomoon' !important;
      content: "\e90b";
    }
  }

  .input-file:hover + .input-file-trigger,
  .input-file:focus + .input-file-trigger,
  .input-file-trigger:hover,
  .input-file-trigger:focus {
    background: #fff;
    color: $orange;
  }

  .input-file-trigger {
    background: $orange;
    color: #fff;
    min-height: 44px;
    padding: .7rem;
    font-size: 1rem !important;
    border: 2px solid $orange;
  }
}

.sales {
  padding: 1rem;
  border: 1px solid $lines;

  &-content {
    max-width: 400px;

  }

  label {
    font-size: .88rem;
  }

  input {
    padding: .7rem;
    background: $lightgrey;
    border: none;

    &.btn {
      margin-top: 1rem;
      background: $orange;
      padding: .5rem 1rem;

      &:hover {
        background: $blue;
      }
    }
  }

  &-list {
    margin: 1rem 0 2rem;
    padding: 0 0 0 2rem;

    li {
      position: relative;
      list-style: square;
      padding: .2rem 1.8rem 0 0;
    }

    .delete {
      position: absolute;
      right: 0;
      font-style: normal;
    }
  }
}

.error {
  color: $red;
  font-size: .85rem;
  line-height: 1rem;
}

.lightbox-gallery {
  display: flex;
  width: calc(100% + 1rem);
  flex-flow: row wrap;
  margin: 0 -.5rem;
}

.lightbox-gallery div > img {
  width: auto;
  height: auto;
  min-height: 100%;
  max-height: 370px;
  max-width: 250px;
  display: block;
  cursor: pointer;
}

.lightbox-gallery div {
  margin: .5rem;
  width: calc(25% - 1rem);
  height: 100%;
  overflow: hidden;
  max-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $lines;
  transition: all .5s;

  &:hover {
    opacity: .8;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, .3);
  }
}

.lightbox {
  display: none;
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  box-sizing: border-box;

  &.fast {
    display: flex;
    overflow: auto;
  }
}

.lightbox img {
  display: block;
  margin: auto;
  max-width: 750px;
}

.loading {
  animation: rotation 2s infinite linear;
  -webkit-animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
      transform: rotate(359deg);
  }
  to {
      transform: rotate(0deg);
  }
}

@-webkit-keyframes rotation {
  from {
      -webkit-transform: rotate(359deg);
  }
  to {
      -webkit-transform: rotate(0deg);
  }
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.verified {
  color: $green;
}

.notVerified {
  color: $red;
}

.padding {
  padding: 1rem;
}

table td, table th {
  hyphens: auto;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
}